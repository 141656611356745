.services_page {
	.anchor {
		top: -104px;
	}
	.banner {
		background-color: #092f57;
		padding: 90px 0px 52px;

		h2 {
			color: #43c7e7;
			margin-bottom: 112px;
			letter-spacing: 2px;
		}

		.secs_wrpr {
			height: 262px;
			padding: 0px 24px;
			width: 100%;

			.sec_wrpr {
				width: calc(100% / 3);
				text-decoration: none;

				.icon_wrpr {
					height: 190px;
					width: 190px;
					border-radius: 50%;
					background-color: #43c7e7;
				}

				p {
					margin-top: 46px;
					color: white;
				}
			}
		}

		.scroll_arrow_wrpr {
			margin-top: 108px;

			.icon {
				height: 18px;
				width: 48px;
				background-image: url("../../assets/white-down-arrow.png");
			}
		}
	}

	.sub_banner {
		height: 464px;
		background-image: url("../../assets/services-banner-bg.png");
		background-position: center center;

		&.instltns {
			background-image: url("../../assets/instalation-banner.png");
			height: 430px;

			// 092f57

			.content_container {
				h4,
				h5,
				.sub_text {
					color: #ffffff;
				}
			}
		}

		&.inspctns {
			background-image: url("../../assets/inspc-banner.png");
			height: 430px;
			.content_container {
				.devider {
					background-color: #092f57;
				}
			}
		}
		.content_container {
			height: 100%;

			h4 {
				color: #28314d;
				margin-top: 86px;
			}

			.sub_text {
				color: #2d3434;
				margin-top: 12px;
			}
			.devider {
				margin-top: 46px;
				display: block;
				width: 166px;
				height: 3px;
				background-color: #dddddd;
				border-radius: 3px;

				&:after {
					content: "";
					display: block;
					width: 48px;
					height: 10px;
					border-radius: 10px;
					background-color: #fac700;
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
				}
			}

			h5 {
				color: #2d3434;
				margin-top: 52px;
			}
		}
	}

	.write_up_sec {
		height: 605px;
		background-color: #efefef;

		.content_container {
			height: 100%;
			width: 100%;
			justify-content: center;
			align-items: flex-start;
			padding-left: 64px;

			.bg_container {
				background-image: url("../../assets/bg-only.png");
				height: 100%;
				width: 100%;
				position: absolute;
				left: 0px;
				bottom: 0px;
				background-repeat: no-repeat;
				background-position: right -156px bottom 0px;
			}

			.bubble_container {
				background-image: url("../../assets/speech-bubble-ony.png");
				background-position: right 44px bottom 30px;
				height: 100%;
				width: 100%;
				position: absolute;
				left: 0px;
				bottom: 0px;
				background-repeat: no-repeat;
			}

			p {
				line-height: 38px;
			}

			.devider {
				height: 3px;
				width: 80px;
				background-color: #f5be0b;
				margin: 14px 0px;
			}

			h5 {
				margin-top: 65px;
			}

			.contact_btn {
				padding: 17px 35px;
				border: none;
				background: #092f57;
				border-radius: 30px;
				box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.2);
				margin-top: 60px;
				cursor: pointer;
				color: white;
				text-decoration: none;

				&:after {
					content: "";
					height: 27px;
					width: 30px;
					display: block;
					background-image: url("../../assets/arrow-btn-icn-white.png");
					margin-left: 28px;
					transform: translateX(0px);
					transition: all 0.5s;
				}
			}
		}
	}

	.list {
		padding-top: 40px;
		.list_line {
			height: 70px;
			color: white;

			&:before {
				content: "";
				display: block;
				height: 33px;
				width: 35px;
				background-image: url("../../assets/list-check.png");
				margin-right: 28px;
			}
		}
	}

	.instl_points {
		background-color: #28314d;
		padding: 64px 30px 70px;
		color: white;

		h3 {
			color: white;
		}

		.box {
			border: 2px solid #43c7e7;
			max-width: 1154px;
			width: 100%;
			min-height: 148px;
			margin-top: 38px;
			color: white;
			padding-top: 10px 16px;
			margin-bottom: 64px;

			p {
				max-width: 1014px;
				line-height: 26px;
				margin-top: 10px;
			}
		}

		.c_us_btn {
			padding: 17px 35px;
			border: none;
			background: #52cfeb;
			border-radius: 30px;
			box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.2);
			margin-top: 30px;
			cursor: pointer;
			// display: inline-block;
			text-decoration: none;
			color: white;

			&:hover {
				&:after {
					transform: translateX(5px);
				}
			}

			&:after {
				content: "";
				height: 27px;
				width: 30px;
				display: block;
				background-image: url("../../assets/arrow-btn-icn-white.png");
				margin-left: 28px;
				transform: translateX(0px);
				transition: all 0.5s;
			}
		}
	}

	.inspct_points {
		background-color: #52cfeb;
		.inner_wrpr {
			margin: 86px 56px 58px;
			background-color: white;
			width: calc(100% - 112px);
			padding: 36px 110px 54px;

			& > p {
				margin-top: 34px;
			}
		}

		.list {
			border-bottom: 2px solid #092f57;
			padding-bottom: 40px;

			.list_line {
				color: #092f57;
				&:before {
					background-image: url("../../assets/list-check-dark.png");
				}
			}
		}

		button,
		.button {
			padding: 17px 35px;
			border: none;
			background: #092f57;
			color: #ffffff;
			border-radius: 30px;
			box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.2);
			margin-top: 30px;
			cursor: pointer;
			margin: 30px auto 0px;
			text-decoration: none;

			&::after {
				content: "";
				height: 27px;
				width: 30px;
				display: block;
				background-image: url("../../assets/arrow-btn-icn-white.png");
				margin-left: 28px;
				transform: translateX(0px);
				transition: all 0.5s;
			}
		}
	}

	.sub_banner.bottom {
		height: 185px;
		background-color: #f9be00;
		background-image: none;
		color: #28314d;

		p {
			margin: 18px 0px;
		}

		.cu_btn {
			height: 62px;
			width: 320px;
			border: none;
			background-color: white;
			border-radius: 30px;
			margin-left: 132px;
			box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.2);
			text-decoration: none;
			color: #092f57;

			&:after {
				content: "";
				height: 27px;
				width: 30px;
				display: block;
				background-image: url("../../assets/arrow-btn-icn.png");
				margin-left: 12px;
				transform: translateX(0px);
				transition: all 0.5s;
			}
		}
	}
}
