@import "./colors.scss";

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}
body {
	line-height: 1;
}
ol,
ul {
	list-style: none;
}
blockquote,
q {
	quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
	content: "";
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/* end css reset */

*,
*:before,
*:after {
	box-sizing: border-box;
	position: relative;
	outline: none;
}

// font families

@font-face {
	font-family: "hemmet";
	src: url("./assets/fonts/hemmet/Hemmet_Personal_Use_Only.ttf");
}

.ffhemmet {
	font-family: "hemmet";
}

@font-face {
	font-family: "mont-bold";
	src: url("./assets/fonts/montserrat/Montserrat-Bold.ttf");
}

.ffmb {
	font-family: "mont-bold";
}

@font-face {
	font-family: "mont-med";
	src: url("./assets/fonts/montserrat/Montserrat-Medium.ttf");
}

.ffmsb {
	font-family: "mont-sb";
}

@font-face {
	font-family: "mont-sb";
	src: url("./assets/fonts/montserrat/Montserrat-SemiBold.ttf");
}

.ffmm {
	font-family: "mont-med";
}

@font-face {
	font-family: "mont-reg";
	src: url("./assets/fonts/montserrat/Montserrat-Regular.ttf");
}

.ffmr {
	font-family: "mont-reg";
}

// font sizes

.fs12 {
	font-size: 12px;
}
.fs12_5 {
	font-size: 12.5px;
}

.fs13_5 {
	font-size: 13.5px;
}

.fs14_5 {
	font-size: 14.5px;
}

.fs15 {
	font-size: 15px;
}

.fs15_5 {
	font-size: 15.5px;
}

.fs16_5 {
	font-size: 16.5px;
}

.fs17 {
	font-size: 17px;
}

.fs18 {
	font-size: 18px;
}

.fs18_75 {
	font-size: 18.75px;
}

.fs20 {
	font-size: 20px;
}

.fs23 {
	font-size: 23px;
}

.fs25 {
	font-size: 25px;
}

.fs33_5 {
	font-size: 33.5px;
}

.fs40 {
	font-size: 40px;
}

.fs42 {
	font-size: 42px;
}

.fs50 {
	font-size: 50px;
}

.fs64 {
	font-size: 64px;
}

.fs70 {
	font-size: 70px;
}
// text transformations

.ttuc {
	text-transform: uppercase;
}

.tac {
	text-align: center;
}

// colors

.bc {
	background-color: $brand-blue;
}

.tbc {
	color: $brand-blue;
}

.content_container {
	max-width: 1364px;
	width: 100%;
	margin: 0px auto;
}

.df {
	display: flex;

	&.ffc {
		flex-flow: column;
	}

	&.acsa {
		align-items: center;
	}

	&.acma {
		justify-content: center;
	}

	&.acc {
		justify-content: center;
		align-items: center;
	}

	.mla {
		margin-left: auto;
	}
}

button {
	cursor: pointer;
	transition: all 0.2s;

	&:hover {
		// transform: scale(1.015);
	}
}
