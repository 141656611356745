@import "../../colors.scss";

.about {
	padding-bottom: 74px;

	.abt_landing_sec {
		height: 830px;
		background-image: url("../../assets/about-banner.jpg");
		background-position: center;

		h2 {
			padding-top: 54px;
			margin-bottom: 30px;
		}

		.content_container {
			height: 100%;

			.wrpr {
				height: 657px;
				width: 723px;
				.img_wrpr {
					background-image: url("../../assets/clip-board.png");
					height: 657px;
					width: 723px;
				}

				.text_wrpr {
					height: 657px;
					width: 723px;
					position: absolute;
					top: 0px;
					right: 0px;
					color: #092f57;

					h3 {
						margin: 94px 10px 0px 80px;
					}

					p {
						margin: 20px 0px 10px 80px;
						line-height: 29px;
						max-width: 554px;
					}

					.sig {
						background-image: url("../../assets/ceo-sig.png");
						height: 77px;
						width: 249px;
						margin: 70px 0px 0px 80px;
					}
				}
			}
		}
	}

	.devider {
		margin-top: 36px;

		display: block;
		width: 166px;
		height: 3px;
		background-color: $brand-light-blue;
		border-radius: 3px;

		&:after {
			content: "";
			display: block;
			width: 48px;
			height: 10px;
			border-radius: 10px;
			background-color: $brand-yellow;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}
	}

	.lws {
		height: 586px;
		background-color: #092f57;
		color: white;

		.content_container {
			height: 100%;

			h4 {
				margin-bottom: 24px;
			}

			.devider {
				margin-top: 36px;
				margin-bottom: 70px;
				background-color: $brand-light-blue;

				&:after {
					background-color: $brand-yellow;
				}
			}

			.list_wrpr {
				max-width: 843px;
				width: 100%;
				flex-wrap: wrap;
				margin-top: 54px;

				.location_wrpr {
					width: 33.333%;
					height: 76px;

					&::before {
						content: "";
						height: 30px;
						width: 24px;
						margin-right: 10px;
						background-image: url("../../assets/map-pin.png");
					}
				}
			}
		}
	}

	.wcc {
		h4 {
			margin-top: 74px;
		}

		.devider {
			margin-top: 55px;
			margin-bottom: 84px;
			background-color: $brand-blue;

			&:after {
				background-color: $brand-yellow;
			}
		}

		.line {
			width: 100%;
			max-width: 1030px;
			margin-bottom: 86px;

			.img {
				height: 186px;
				width: 186px;
				margin-right: 62px;

				&.pro {
					background-image: url("../../assets/pro-img.png");
				}

				&.state_of_art {
					background-image: url("../../assets/stste-of-art-img.png");
				}

				&.compliant {
					background-image: url("../../assets/compliant.png");
				}
			}

			p {
				line-height: 25px;
				margin-top: 20px;
			}
		}
	}
}
