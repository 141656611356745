@import "../../colors.scss";

.contact {
  .banner {
    background-image: url("../../assets/contact-bg.png");
    background-size: 100% auto;
    background-position: center;
    height: 306px;
    border-bottom: 10px solid;
    box-sizing: content-box;

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0px;
      left: 0px;
      height: 100%;
      width: 100%;
      background-color: white;
      opacity: 0.875;
    }

    .content_container {
      height: 100%;

      p {
        margin-top: 40px;
        line-height: 25px;
      }
    }
  }

  .form_sec {
    .content_container {
      max-width: 1060px;
      width: 100%;
      padding: 0px 25px;

      .header {
        width: 100%;
        border-bottom: 1px solid #d7d7d7;
        padding-top: 66px;
        padding-bottom: 54px;

        .sec {
          width: 50%;

          .icon_wrpr {
            height: 82px;
            width: 82px;
            margin-right: 20px;

            &::after {
              content: "";
              display: block;
            }

            &.phone {
              &::after {
                background-image: url("../../assets/phone-icn.png");
                height: 69px;
                width: 58px;
              }
            }

            &.email {
              &::after {
                background-image: url("../../assets/email-icn.png");
                height: 80px;
                width: 82px;
              }
            }
          }

          .content {
            .lbl {
              margin-bottom: 24px;
            }
          }
        }
      }
    }

    .form_wrpr {
      width: 100%;

      .hdr {
        margin-top: 54px;
        margin-bottom: 64px;
        text-align: center;
      }

      .form {
        width: 100%;
        border-bottom: 1px solid #d7d7d7;
        padding-bottom: 40px;

        .left_sec {
          width: 50%;
          max-width: 422px;
        }

        .right_sec {
          width: 50%;
          margin-left: auto;
          max-width: 422px;

          .checkboxes_wrpr {
            .chcbx_line {
              height: 44px;

              &:nth-of-type(1) {
                margin-top: 6px;
              }

              input {
                display: none;
              }

              label {
                &::before {
                  content: "";
                  height: 22px;
                  width: 22px;
                  display: block;
                  border: 2px solid $brand-light-blue;
                  margin-right: 22px;
                  background-color: transparent;
                  transition: all 0.275s;
                }
              }

              input:checked ~ label {
                &::before {
                  background-color: $brand-light-blue;
                }
              }
            }
          }
        }

        .inpt_wrpr {
          height: 85px;
          width: 100%;
          margin-bottom: 32px;

          &:nth-child(2) {
            margin-left: auto;
          }

          label {
            margin-bottom: 9px;
            display: block;
          }

          input {
            height: 60px;
            display: block;
            width: 100%;
            padding: 0px 22px;
            font-size: 22px;
            border: 2px solid $brand-light-blue;
          }
        }
      }

      .btn_wrpr {
        .sbmt_btn {
          background-color: $brand-light-blue;
          color: white;
          border: none;
          text-align: center;
          align-self: center;
          padding: 17px 77px;
          border-radius: 33px;
          margin: 44px 0px 162px;
        }
      }
    }
  }

  .map_sec {
    height: 395px;
    background-image: url("../../assets/map-bg.png");
    background-size: auto 100%;
    background-position: center;
  }
}
