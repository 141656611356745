.room_sec {
	.left_sec {
		width: 48%;

		p {
			max-width: 435px;
			letter-spacing: 0.25px;
			word-spacing: 3px;
			line-height: 34px;
			margin-top: 30px;
		}

		.button {
			text-decoration: none;
			background-color: #092f57;
			color: white;
			border: none;
			padding: 17px 32px;
			border-radius: 30px;
			margin-top: 30px;
			box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.2);
			display: inline-block;
		}
	}

	.right_sec {
		width: 52%;
		height: 680px;
		margin: 60px 0px;
	}

	.ils {
		.right_sec {
			background-image: url("../../../assets/room-image.png");
			// background-size: 100%;
			background-repeat: no-repeat;
			border-radius: 280px 0px 0px 280px;
			background-position: -60px center;
		}
	}

	.content_container {
		position: absolute;
		top: 0px;
		left: 50%;
		transform: translateX(-50%);
		padding: 0px 42px;
	}
}
