@import "../../../colors.scss";

.landing_sec {
  height: calc(100vh - 126px);
  background-color: $brand-blue;
  overflow: hidden;

  .content_container {
    height: 100%;
    padding: 0px 42px;

    .left_wrpr {
      width: 50%;
      h2 {
        font-size: 56px;
        color: $brand-light-blue;
        line-height: 68px;
        margin-bottom: 26px;
      }

      h4 {
        color: white;
        line-height: 28px;
      }

      .btns_wrpr {
        margin-top: 64px;

        .btn {
          padding: 16px 22px;
          border-radius: 30px;
          text-decoration: none;
          margin-right: 18px;
        }

        .rq_btn {
          background-color: $brand-yellow;
          color: $brand-blue;

          &::after {
            content: "";
            display: block;
            height: 22px;
            width: 25px;
            margin-left: 12px;
            background-image: url("../../../assets/rb-icon.png");
          }
        }

        .lm_btn {
          color: $brand-yellow;
          border: 1px solid $brand-yellow;
          padding: 14px 46px;
          //   background-color: $brand-blue;
        }
      }
    }

    .vans_bg {
      flex-shrink: 0;
      height: 392px;
      width: 870px;
      background-image: url("../../../assets/vans-desktop.png");
      z-index: 1;
      transition: all 1.5s;
      // margin-right: -44px;
    }

    .logo_bg {
      height: 100%;
      width: 690px;
      background-image: url("../../../assets/logo-bg.png");
      position: absolute;
      right: 0px;
      background-position: center bottom;
    }
  }
}
