.recent_projects {
  background-color: #43c7e7;
  height: 815px;

  .content_container {
    height: 100%;
    padding: 0px 34px;

    h3 {
      color: #28314d;
    }

    .cards_wrpr {
      width: 100%;
      max-width: 1296px;
      margin-top: 58px;

      .card_wrpr {
        height: 492px;
        background-color: white;
        width: 50%;
        border-radius: 5px;
        box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.2);

        &:nth-child(1) {
          margin-right: 10px;

          .quote_wrpr {
            .logo_wrpr {
              &:after {
                height: 38px;
                width: 121px;
                background-image: url("../../../assets/sentenial-logo.png");
              }
            }
          }

          .img {
            background-image: url("../../../assets/sentinel-amsterdam-home-2.jpg");
          }
        }

        &:nth-child(2) {
          margin-left: 18px;

          .img {
            background-image: url("../../../assets/home-img.png");
          }

          .quote_wrpr {
            .logo_wrpr {
              width: 206px;
              flex-shrink: 0;

              &:after {
                height: 76px;
                width: 114px;
                background-image: url("../../../assets/pine-valley-logo.png");
              }
            }

            .quote {
              padding-right: 106px;
            }
          }
        }

        .card_header {
          height: 52px;
          padding: 0px 22px;

          .lbl {
            color: #818181;
            margin-right: 4px;
          }

          .val {
            color: #092f57;
          }
        }

        .img {
          height: 242px;
          width: 100%;
          background-size: 100%;
        }

        .quote_wrpr {
          height: 198px;

          .logo_wrpr {
            width: 206px;
            flex-shrink: 0;

            &:after {
              content: "";
              display: block;
              height: 76px;
              width: 114px;
              background-image: url("../../../assets/pine-valley-logo.png");
            }
          }
          .quote {
            padding-top: 24px;
            height: 100%;
            line-height: 20px;
            letter-spacing: 0.1px;
            padding-right: 20px;
          }

          .sig_wrpr {
            height: 74px;
            color: #28314d;
          }

          .sig_wrpr {
            .sig {
              background-size: 100%;
            }

            .title {
              margin-top: 5px;
              margin-left: 9px;
            }
          }
        }
      }
    }

    .take_a_look {
      padding: 17px 35px;
      border: none;
      background: white;
      border-radius: 30px;
      box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.2);
      margin-top: 60px;
      cursor: pointer;

      &:hover {
        &:after {
          transform: translateX(5px);
        }
      }

      &:after {
        content: "";
        height: 27px;
        width: 30px;
        display: block;
        background-image: url("../../../assets/arrow-btn-icn.png");
        margin-left: 28px;
        transform: translateX(0px);
        transition: all 0.5s;
      }
    }
  }
}
