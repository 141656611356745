.footer {
	height: 492px;
	background-color: #092f57;
	color: white;

	.top_sec {
		height: 424px;
		padding-top: 96px;

		.list_wrpr {
			width: 250px;

			ul {
				margin-top: 28px;

				li {
					height: 42px;

					&::before {
						content: "";
						display: block;
						height: 8px;
						width: 8px;
						background-color: #f9be00;
						margin-right: 18px;
					}

					a {
						color: white;
						text-decoration: none;
					}
				}
			}
		}

		.logo_wrpr {
			height: 115px;
			width: 317px;
			background-image: url("../../assets/logo-footer.png");
			margin: 14px 0px 46px;
		}

		p {
			margin-bottom: 20px;
		}
	}

	.bottom_sec {
		height: 68px;
		background-color: #072341;

		.content_container {
			height: 100%;

			a {
				color: white;
				text-decoration: none;
			}
		}
	}
}

// 317 / 115
