@import "../../colors.scss";

.main_hdr_wrpr {
	position: sticky;
	top: 0px;
	z-index: 9;
	background: white;

	.main_hdr {
		height: 94px;
		width: 100%;
		background-color: white;
		padding: 0px 26px 0px 12px;

		.logo_wrpr {
			.logo {
				background-image: url("../../assets/logo.png");
				height: 68px;
				width: 193px;
			}
		}

		.navs_wrpr {
			height: 100%;

			.nav {
				padding: 0px 20px;
				text-decoration: none;

				&.selected {
					&:after {
						content: "";
						display: block;
						height: 3px;
						width: 100%;
						position: absolute;
						left: 0px;
						bottom: 0px;
						background-color: $brand-yellow;
					}
				}
			}
		}
	}
}
