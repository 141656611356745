@import "../../colors.scss";

.pom {
	background-color: $brand-light-blue;
	height: 268px;
	.content_container {
		height: 100%;

		h4 {
			color: $text-color;
		}

		p {
			line-height: 25px;
			margin-top: 20px;
		}

		.raq {
			padding: 17px 35px;
			border: none;
			background: white;
			border-radius: 30px;
			box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.2);
			margin-top: 30px;
			cursor: pointer;
			text-decoration: none;

			&:hover {
				&:after {
					transform: translateX(5px);
				}
			}

			&:after {
				content: "";
				height: 27px;
				width: 30px;
				display: block;
				background-image: url("../../assets/arrow-btn-icn.png");
				margin-left: 28px;
				transform: translateX(0px);
				transition: all 0.5s;
			}
		}
	}
}
