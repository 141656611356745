@import "../../../colors.scss";

.hwch_sec {
	margin-top: 94px;
	margin-bottom: 152px;

	.content_container {
		padding: 0px 30px;
	}

	//   .con
	.devider {
		margin-top: 38px;

		display: block;
		width: 166px;
		height: 3px;
		background-color: #dddddd;
		border-radius: 3px;

		&:after {
			content: "";
			display: block;
			width: 48px;
			height: 10px;
			border-radius: 10px;
			background-color: $brand-yellow;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}
	}

	.sections_wrpr {
		width: 100%;
		section {
			width: 33.333%;
			background-color: #f1f1f1;
			justify-content: center;
			border-radius: 5px;
			border: 1px solid #d5d5d5;
			height: 388px;
			margin-top: calc(190px / 2 + 40px);

			.icon_wrpr {
				background-color: #f7c712;
				height: 190px;
				width: 190px;
				border-radius: 50%;
				transform: translateY(-50%);
				position: absolute;
				top: 0px;
			}

			&:nth-child(2) {
				margin: 0px 22px;
				margin-top: calc(190px / 2 + 40px);
			}

			h4 {
				color: #2d3434;
				margin-top: 86px;
			}

			p {
				margin-top: 38px;
				max-width: 354px;
				letter-spacing: 0.25px;
				height: 96px;
				line-height: 20px;
			}

			.button {
				padding: 17px 44px;
				background-color: #092f57;
				color: white;
				border: none;
				border-radius: 40px;
				text-decoration: none;
			}
		}
	}
}
